import { graphql, PageRendererProps, Link } from "gatsby"
import React from "react"
import { Bio } from "../components/bio"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { Query, SitePageContext } from "../graphql-types"

const greyHover = "text-gray-600 hover:text-gray-800"

interface Props extends PageRendererProps {
  pageContext: SitePageContext
  data: Query
}

const BlogPostTemplate = (props: Props) => {
  const data = props.data!
  const post = data.markdownRemark!
  const excerpt = post.excerpt!
  const frontmatter = post.frontmatter!
  const html = post.html!
  const { previous, next } = props.pageContext

  return (
    <Layout location={props.location} title={""}>
      <SEO
        title={frontmatter.title!}
        description={frontmatter.description || excerpt}
      />
      <Link
        className={`${greyHover} mt-0 fixed left-0 top-0 mt-16 ml-8`}
        to="/"
        style={{ zIndex: 50 }}
      >
        <h3 className="text-lg bg-white mix-blend-mode">← All Posts</h3>
      </Link>
      <h1 className="mt-16 text-2xl text-gray-800">
        {post.frontmatter!.title}
      </h1>
      <p className="block mb-12 mt-0 text-gray-500">{frontmatter.date}</p>
      <div
        className="blogContainer"
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <hr className="mb-32" />
      <div className={`${greyHover}`}>
        <Bio />
        <ul className="flex flew-wrap justify-between p-0 mb-16">
          <li>
            {previous && (
              <Link to={previous.fields!.slug!} rel="prev">
                ← {previous.frontmatter!.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields!.slug!} rel="next">
                {next.frontmatter!.title} →
              </Link>
            )}
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
